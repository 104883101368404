<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Choices from "choices.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import {
    required,
} from "vuelidate/lib/validators";

import {getPMApi} from '@/api/pm'
import { search_agent_property, search_pma_code} from "@/api/misc";



/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",
    
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations: {
        pma_code         : {required},
        property_address : {required},
        tenant_name : {required},
        property: {
            agent_name: { required },
            start_date: { required },
            rent : {required},
            o_code : {required},
        },
  },


  methods: {

    formSubmit() {
    
      this.$v.$touch();
      if (this.$v.$invalid == false) {
        let pm = {
          agent_id : this.property.agent_id,
          address  : this.property_address,
          property_landlord_id       : this.property.property_landlord_id,
          o_code                     : this.property.o_code,
          monthly_manager_commission : this.property.monthly_manager_commission,
          monthly_manger_pay_way     : this.comm_type_rate == true ? 0 : 1,
          is_month_by_month          : this.property.is_month_by_month? 1 : 0,

          deposit      : 0,
          rent         : this.property.rent,
          parking      : this.property.parking,
          utility_fee  : this.property.utility_fee,
          other_income : this.property.other_income,
          term : {
            start_date : this.property.start_date,
            end_date : this.property.end_date
          },
          landlords    :  this.landlords,
          tenants : [
            {
              name         : this.tenant_name,
              t_payment    : this.tenant_payment_type,
              payment_type : this.tenant_payment_type,
              financial_institution:  this.t_institution,
              transit      : this.t_transit,
              account      : this.t_account,
              bank_account_name :  this.tenant_name
            }
          ]
        }
        getPMApi().setup_new_pm(pm).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Property is Setup" );
            this.$router.push({name : 'pm-property-list'})
          } else {
            this.$alertify.error("Setup Property failed:" + res.errCode );
          }
        })
      }

    },


    onSelectedAgent(agent) {
      this.property.agent_id = agent.agent.id
      this.property.agent_name = agent.agent.first_name + ' '+agent.agent.last_name
    },
    onLandlordAddressSelected(evt) {
      this.landlord_address = evt.full
    },
    onPMAddressSelected(evt) {
      this.property_address              = evt.label
      this.property.property_landlord_id = evt.id
      this.property.o_code               = evt.code
      this.pma_code                      = evt.code
      this.$refs.pma_code_ref.inputValue  = evt.code
     
      this.landlords = []
      evt.landlords.map(landlord => {
        this.landlords.push({
          id                       :landlord.id,
          landlord_name            :landlord.landlord_name,
          landlord_email           :landlord.email1,
          landlord_address         :landlord.address,
          landlord_phone           :landlord.phone1,
          landlord_is_non_resident :landlord.is_resident ? false : true,
          landlord_payment_type    :landlord.rent_type,
          landlord_institution     :landlord.institution,
          landlord_transfer        :landlord.transfer,
          landlord_account         :landlord.account_number,
          owner_ship               :landlord.owner_ship
        })
      })


    },

    onPMACodeSelect(evt)  {
      console.log(evt)
      this.$refs.addr_ref.inputValue     = (evt.apt?evt.apt +' ': '' )+ evt.address
      this.property_address              = (evt.apt?evt.apt +' ': '' )+ evt.address
      this.property.property_landlord_id = evt.landlord_id
      this.property.o_code               = evt.code
      this.property.agent_name           = evt.agent.full_name
      this.property.agent_id             = evt.agent.id
      this.searched_property_address     =  (evt.apt?evt.apt +' ': '' )+evt.address

      //query property landlord  add landlord
      getPMApi().get_owner_by_pma({pma_code : evt.code}).then(res => {
        if (res.errCode == 0) {
          this.landlords = []
          res.data.map(landlord => {
            this.landlords.push({
              id                       :landlord.id,
              landlord_name            :landlord.landlord_name,
              landlord_email           :landlord.email1,
              landlord_address         :landlord.address,
              landlord_phone           :landlord.phone1,
              landlord_is_non_resident :landlord.is_resident ? false : true,
              landlord_payment_type    :landlord.rent_type,
              landlord_institution     :landlord.institution,
              landlord_transfer        :landlord.transfer,
              landlord_account         :landlord.account_number,
              owner_ship               :landlord.owner_ship
            })
          })
        }
      })
      
    },


    queryProperty(str) {
      if (this.property.agent_id) {
        search_agent_property(str, this.property.agent_id).then(res => {
          if (res.errCode == 0) {
            this.properties_data = []
            res.data.map(e => {
              e.label = e.address
              this.properties_data.push(e)
            })
          }
        })
      }
    },

    search_pma(str) {
      search_pma_code(str).then(res =>{
        if (res.errCode == 0) {
            this.pma_list_data = []
            res.data.map(e => {
              e.label = e.code
              this.pma_list_data.push(e)
            })
          }
      })
    },

    onDisabledEndDate(evt) {
      if (evt.target.checked == true) {
        this.property.end_date = ''
      }
    }
   
  },

  data() {
    return {
      title: "PM",

      items: [
      {
          text: "PM",
          href: "/",
        },
        {
          text: "RTA List",
          href: "/pm/property_list",
        },
        {
          text: "Setup New Property",
          active: true,
        },
      ],

      property: {
        agent_id : '',
        rent : '',
        start_date : '',
        end_date   : '',
        agent_name : '',
        is_month_by_month : true,
        monthly_manager_commission : 0,
        monthly_manger_pay_way     : 1,
        landloard : {
          name : ''
        }, 
        tenant : {
          name : '',
          phone:'',
          email : '',
        }
      },

      landlords: [],


      comm_type_rate : false,
      tenant_name : '',
      tenant_payment_type : 'PAD',
      t_institution : '',
      t_transit : '',
      t_account : '',
      property_address : '',
      searched_property_address : '',
      properties_data : [],
      pma_code : '',
      pma_list_data: [],
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    AgentAutoComplete,
    VueBootstrapTypeahead
    
  },
  mounted() {
    new Choices("#tenant_payment_type", {
      removeItemButton: false,
    });
  },

  watch: {
   
    searched_property_address (new_search_name) {
      this.queryProperty(new_search_name)
    },

    pma_code(new_pma_code) {
        this.search_pma(new_pma_code)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">Basic Info</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">General Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Property Manager</label>
                          <AgentAutoComplete
                                    v-model="property.agent_name"
                                    @onSelected="onSelectedAgent"
                                    autocomplete="off"
                                    :init_data="{style_class : {'is-invalid':$v.property.agent_name.$error}, agent_name : property.agent_name, agent_id : property.agent_id}"
                                />
                          <div v-if="$v.property.agent_name.$error" class="invalid-feedback">
                              <span v-if="!$v.property.agent_name.required">This value is required.</span>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label for="workexperience-companyname-input">Property Code</label>
                          <vue-bootstrap-typeahead 
                              ref="pma_code_ref"
                              v-model="pma_code"
                              :value ="property.o_code"
                              :data="pma_list_data"
                              :inputClass=" {'is-invalid':$v.pma_code.$error}"
                              :serializer="s => s.label"
                              :foramterDisplay="s => s.label"
                              @hit="onPMACodeSelect($event)"
                              autocomplete="off"
                              />
                          <div v-if=" $v.pma_code.$error" class="invalid-feedback">
                              <span v-if="!$v.pma_code.required">This value is required.</span>
                          </div>
                         </div>

                      </div>
                      <div class="col-lg-9">
                        <div class="mb-3">
                          <label for="workexperience-companyname-input">Property Address</label>
                          <vue-bootstrap-typeahead 
                              ref="addr_ref"
                              v-model="searched_property_address"
                              :value ="property_address"
                              :data="properties_data"
                              :inputClass=" {'is-invalid':$v.property_address.$error}"
                              :serializer="s => s.label"
                              :foramterDisplay="s => s.label"
                              @hit="onPMAddressSelected($event)"
                              autocomplete="off"
                              />
                          <div v-if=" $v.property_address.$error" class="invalid-feedback">
                              <span v-if="!$v.property_address.required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
              <!-- end card -->



              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Rent Info</h5>
                  </div>
                </div>
                <div class="card-body">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Rent</label>
                          <input type="text" v-model="property.rent" class="form-control" 
                            placeholder="Enter Amount" :class="{'is-invalid':$v.property.rent.$error}"/>
                          <div v-if="$v.property.rent.$error" class="invalid-feedback">
                              <span v-if="!$v.property.rent.required">This value is required.</span>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-designation-input">Start Date</label>
                          <flat-pickr
                                v-model="property.start_date"
                                placeholder="Select a date"
                                class="form-control"
                                :class="{'is-invalid':  $v.property.start_date.$error }"
                                ></flat-pickr>
                          <div v-if=" $v.property.start_date.$error" class="invalid-feedback">
                            <span v-if="!$v.property.start_date.required">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <!-- 
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-designation-input">End Date</label>
                          <flat-pickr
                                v-model="property.end_date"
                                placeholder="Select a date"
                                class="form-control"
                                :disabled="property.is_month_by_month? true : false"
                                
                                ></flat-pickr>
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="form-check mt-4">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="formrow-customCheck"
                            v-model="property.is_month_by_month"
                            @change="onDisabledEndDate"
                          />
                          <label class="form-check-label" for="formrow-customCheck"
                            >Month by Month</label
                          >
                        </div>
                      </div>

                    -->

                    </div>
                    <!-- end row-->

                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Parking</label>
                          <input type="text" v-model="property.parking" class="form-control" 
                            placeholder="Enter Parking" />
                        
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Utilities</label>
                          <input type="text" v-model="property.utility_fee" class="form-control" 
                            placeholder="Enter Utilities" />
                        
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Other</label>
                          <input type="text" v-model="property.other_income" class="form-control" 
                            placeholder="Enter Other" />
                        
                        </div>
                      </div>
                    </div>
                     <!-- end row-->
                  
                </div>
              </div>
              <!-- end card -->



              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        03
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Commission Info</h5>
                  </div>
                </div>
                <div class="card-body">
                    <div class="row">
                     
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" >Commission</label><label v-if="comm_type_rate == true">(%)</label>
                          <input type="text" v-model="property.monthly_manager_commission" class="form-control" placeholder="Amount or Rate" />
                        </div>
                      </div> 
                    </div>
                    <div class="form-group">
                      <div class="form-check mt-3">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="formrow-customCheck"
                          v-model="comm_type_rate"
                        />
                        <label class="form-check-label" for="formrow-customCheck"
                          >Commission By Rent Rate</label
                        >
                      </div>
                    </div>
                    
                </div>
              </div>
              <!-- end card -->



              <!-- Tenant Block  -->
              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        04
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Tenant Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="mb-3 col-md-3">
                      <label for="contact-info-email-input">Name :</label>
                      <input type="text" class="form-control" v-model="tenant_name" :class="{'is-invalid':  $v.tenant_name.$error }" maxlength="22" />
                        <div v-if=" $v.tenant_name.$error" class="invalid-feedback">
                            <span v-if="!$v.tenant_name.required">This value is required.</span>
                        </div>

                    </div>
                    <div class="mb-3 col-md-3">
                      <label for="contact-info-email-input">Email :</label>
                      <input type="text"  v-model="property.tenant.phone" class="form-control"  placeholder="Enter Email" />
                    </div>
                    <div class="mb-3 col-md-3">
                      <label for="contact-info-email-input">Phone :</label>
                      <input type="text" v-model="property.tenant.number"  class="form-control"  placeholder="Enter Number" />
                    </div>
                    <div class="mb-3 col-md-3">
                      <div class="mb-3">
                              <label for="validationCustom01">PAD Payment</label>
                              <select id="tenant_payment_type" v-model="tenant_payment_type" class="form-control">
                                <option value="PAD" selected>PAD</option>
                                <option value="Cheque">Cheque</option>
                                <option value="ETransfer">E-Transfer</option>
                              </select>
                      </div>
                     
                    </div>
                  </div>


                  <div class="row" v-if="tenant_payment_type == 'PAD'">
                    <div class="col-md-3">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">Financial Institution</label>
                        <input type="text" class="form-control" v-model="t_institution" placeholder="Enter Bank  Institution Number" />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Transit</label>
                        <input type="text" class="form-control" v-model="t_transit" placeholder="Enter Bank Transit Number"
                           />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Account</label>
                        <input type="text" class="form-control" v-model="t_account" placeholder="Enter Bank Account"
                         />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- end card -->


              <!-- Landlord Info -->
              <div class="card border shadow-none">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        05
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Landlord Info</h5>
                  </div>
                  <div class="align-items-start justify-content-md-end ">
                    <!-- <b-button variant="primary" size="sm">Add Landlord</b-button> -->
                  </div>
                </div>
                <div class="card-body" v-for="(landlord,idx) in landlords" :key="'landlord_'+idx">
                  <div class="row">
                    <div class="col-lg-3">
                      <label for="validationCustom04">LandLord Name</label>
                      <input type="text" class="form-control" v-model="landlord.landlord_name"  readonly />
                       
                    </div>
                    <div class="col-lg-3">
                      <label for="validationCustom04">LandLord Email</label>
                      <input type="text" class="form-control" v-model="landlord.landlord_email" readonly />
                    </div>
                    <div class="col-lg-3">
                      <label for="validationCustom04">LandLord Phone</label>
                      <input type="text" class="form-control" v-model="landlord.landlord_phone" readonly />
                      
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-9">
                      <div class="mb-3">
                        <label for="workexperience-companyname-input">Address</label>
                        <input type="text" class="form-control" v-model="landlord.landlord_address" readonly />

                      </div>
                    </div>
                   
                  </div>


                  <div class="form-group row">
                    <div class="form-check mt-3 mb-3 col-md-3">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="formrow-customCheck"
                        v-model="landlord.landlord_is_non_resident"
                        :disabled="true"
                      />
                      <label class="form-check-label" for="formrow-customCheck"
                        >Non Resisent?</label
                      >
                    </div>

                    <div class="form-check mt-3 mb-3 col-md-3">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="formrow-customCheck"
                        :disabled="true"
                        :checked="landlord.landlord_payment_type == 'EFT' ? 'checked' :''"
                        @change="landlord.landlord_payment_type = $event.target.checked == true ?'EFT' : 'CHEQUE'"
                        
                      />
                      <label class="form-check-label" for="formrow-customCheck"
                        >EFT Payment</label
                      >
                    </div>
                  </div>


                  <div class="row" v-if="landlord.landlord_payment_type == 'EFT'">
                    <div class="col-md-3">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label">Financial Institution</label>
                        <input type="text" class="form-control" v-model="landlord.landlord_institution" placeholder="Enter Bank  Institution Number" readonly />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Transit</label>
                        <input type="text" class="form-control" v-model="landlord.landlord_transfer" placeholder="Enter Bank Transit Number" readonly 
                           />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label">Financial Account</label>
                        <input type="text" class="form-control" v-model="landlord.landlord_account" placeholder="Enter Bank Account"  readonly
                         />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- end card -->

              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                    <button type="button" class="btn btn-secondary w-sm" @click="$router.push({name: 'pm-property-list'})"> Cancel </button>
                    <button type="submit" class="btn btn-primary w-sm">
                      Setup RTA
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
